import { Box, Circle, Icon, Text, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import EmojiPicker, { Emoji, EmojiClickData, EmojiStyle, SkinTones } from 'emoji-picker-react';
import isEmpty from 'lodash/isEmpty';
import { LuSmilePlus } from 'react-icons/lu';

interface Props {
	value: EmojiClickData['emoji'];
	error: boolean;
	onIconSelected: (emojiUnified: EmojiClickData['emoji']) => void;
}

export const IconPicker: React.FC<Props> = ({ value, error, onIconSelected }) => {
	const [flag, setFlag] = useBoolean();
	const ref = useRef<HTMLDivElement>();

	const handleClickOutside = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setFlag.off();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleOnClick = (emoji: EmojiClickData) => {
		setFlag.off();
		onIconSelected(emoji.emoji);
	};

	return (
		<Box ref={ref as React.LegacyRef<HTMLDivElement>}>
			{flag ? (
				<Box zIndex={1} position='absolute' left='50%' transform='translateX(-50%)' mt='4.7rem'>
					<EmojiPicker
						onEmojiClick={handleOnClick}
						autoFocusSearch={false}
						defaultSkinTone={SkinTones.LIGHT}
						emojiStyle={EmojiStyle.NATIVE}
						width='100%'
						// reactionsDefaultOpen={true}
						// skinTonesDisabled
					/>
				</Box>
			) : null}
			<Circle
				size={66}
				backgroundColor='buttonColor'
				color='black'
				onClick={setFlag.toggle}
				_hover={{ cursor: 'pointer' }}
				border='borderStroke'
				borderColor={!error ? 'brand' : 'red'}
			>
				{isEmpty(value) ? (
					<Icon as={LuSmilePlus} boxSize='8' color={!error ? 'buttonTextColor' : 'red'} />
				) : (
					<Text display='flex' fontSize='4xl'>
						{value}
					</Text>
				)}
			</Circle>
			<Emoji unified={value} />
		</Box>
	);
};

IconPicker.defaultProps = {
	value: '',
	error: false,
};
