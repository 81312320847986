import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
	// define the part you're going to style
	container: {
		backgroundColor: 'cardBackgroundColor',
	},
	control: {
		width: '25px',
		height: '25px',
		border: 'none',
		_checked: {
			color: 'buttonTextColor',
			bgColor: 'buttonColor',
			border: 'none',
		},
	},
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
