import React from 'react';
import { Box, Flex, Grid, GridItem, Text, useToken } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useComicData } from '../../hooks/useComicData.ts';
import { Loading } from '../../../commons/components/Loading';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

// Additional CSS for sketchy border
const sketchyBorderCSS = {
	'&:before': {
		content: '""',
		position: 'absolute',
		top: '-2px',
		right: '-2px',
		bottom: '-2px',
		left: '-2px',
		zIndex: '2',
		border: '2px black solid',
		transform: 'rotate(-1deg)',
	},
};

// Additional CSS for text box inside card
const textBoxCSS = {
	p: '4',
	position: 'relative',
	bg: 'white',
	transform: 'rotate(1deg)',
	mb: '2',
	ml: '1',
};

export const ComicVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	// const conf = visualization.configuration as DrawingCardsConfiguration;
	const { data, isLoading } = useComicData(presentationId, visualization.id);
	const [comicsFontFamily] = useToken('fonts', ['comics']);

	if (isLoading) {
		return <Loading />;
	}

	// Function to dynamically set the border style
	const getDynamicBorderStyle = (index: number) => ({
		borderTop: index % 2 === 0 ? '2px black solid' : 'none',
		borderRight: '2px black solid',
		borderBottom: index % 2 !== 0 ? '2px black solid' : 'none',
		borderLeft: 'none',
	});

	// Define the number of columns and gap size for different breakpoints
	const gridTemplateColumns = {
		base: 'repeat(1, 1fr)', // base
		sm: 'repeat(2, 1fr)', // 640px
		md: 'repeat(3, 1fr)', // 768px
		lg: 'repeat(4, 1fr)', // 1024px
	};
	const gridGap = {
		base: 4,
		sm: 6,
		md: 8,
		lg: 8,
	};

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data?.entries)}>
			<Grid templateColumns={gridTemplateColumns} gap={gridGap} maxHeight='70%' overflowY='auto' p={2} mx={16} mb={8}>
				{data?.entries?.map((entry, idx) => (
					<GridItem
						key={entry.heading}
						as={Flex}
						direction={idx % 2 === 0 ? 'column-reverse' : 'column'} // Reverses order for even items
						position='relative'
						sx={sketchyBorderCSS}
						bg='white'
					>
						<Box pt={0} mr={4} sx={{ ...textBoxCSS, ...getDynamicBorderStyle(idx) }}>
							<Flex flexDirection='column'>
								<Text wordBreak='break-word' fontFamily={comicsFontFamily} my={2} fontSize='lg' textColor='black'>
									{entry.heading}
								</Text>
								<Text fontSize='sm' fontFamily={comicsFontFamily} textColor='black'>
									{entry.text}
								</Text>
							</Flex>
						</Box>
						<Box flex='1' alignSelf='center'>
							<div>
								<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(entry.drawing)))}`} alt='SVG Content' style={{ width: '100%', height: '100%' }} />
							</div>
						</Box>
					</GridItem>
				)) ?? null}
			</Grid>
		</VisualizationWrapper>
	);
};
