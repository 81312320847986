import { useToken } from '@chakra-ui/react';
import includes from 'lodash/includes';
import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import truncate from 'lodash/truncate';
import { PieChartConfiguration } from '../../model/Visualization.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { usePieChartData } from '../../hooks/usePieChartData.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const PieChartVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as PieChartConfiguration;
	const { data, isLoading } = usePieChartData(presentationId, visualization.id);
	const [successColor, textColor] = useToken('colors', ['greenSuccess', 'textColor']);

	const selectedAnswers = conf.selectedAnswers;
	const pieData = data?.map((pieData) => ({
		truncatedCategory: truncate(pieData.category, { length: 20 }),
		escapedCategory: insertNewlineEveryAmountWords(pieData.category),
		value: pieData.value,
		color: includes(selectedAnswers, pieData.category) ? successColor : pieData.color,
	}));

	const chartRef = useRef<am5percent.PieChart | null>(null);
	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(pieData)) {
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
			const chart = root.container.children.push(
				am5percent.PieChart.new(root, {
					height: am5.percent(80),
					layout: root.verticalLayout,
					radius: am5.percent(80),
					innerRadius: conf.variant === 'PIE' ? undefined : am5.percent(50),
				})
			);

			const series = chart.series.push(
				am5percent.PieSeries.new(root, {
					name: 'Series',
					categoryField: 'truncatedCategory',
					valueField: 'value',
					fillField: 'color', // Alternative: //https://www.amcharts.com/docs/v5/concepts/settings/template-fields/
				})
			);

			const tooltip = am5.Tooltip.new(root, {
				labelText: '{escapedCategory}',
				pointerOrientation: 'horizontal',
			});

			series.set('tooltip', tooltip);
			//https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/pie-series/#label-content
			series.labels.template.set('text', "{category}: [bold] {valuePercentTotal.formatNumber('0.')}%[/]");

			if (!conf.showFloatingLegend) {
				series.ticks.template.set('forceHidden', true);
				series.labels.template.set('forceHidden', true);
			}

			series.data.setAll(pieData!);

			if (conf.showFooterLegend) {
				// Create legend
				// https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
				const legend = chart.children.push(
					am5.Legend.new(root, {
						centerX: am5.p50,
						x: am5.p50,
						marginTop: 15,
						marginBottom: 15,
						layout: am5.GridLayout.new(root, {
							maxColumns: 3,
							fixedWidthGrid: true,
						}),
					})
				);
				legend.labels.template.adapters.add('fill', () => am5.color(textColor));
				legend.data.setAll(series.dataItems);
			}

			series.appear();
			chart.appear();
			// Set the chart reference for possible cleanup on unmount
			chartRef.current = chart;
		}

		return () => {
			// Clean up on unmount
			root.dispose();
		};
	}, [pieData]);

	return (
		<VisualizationChartWrapper
			title={visualization.title}
			description={visualization.description}
			isLoading={isLoading}
			reference={conf.reference}
			referenceColor={successColor}
			isNoData={isEmpty(pieData)}
		/>
	);
};
